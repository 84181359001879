<template>
    <div class="maincontent">
        <div class="container">
            <div class="row">
                <div class="col-12 headerPic" style="height:500px">
                    <!-- Vueper Slide paralax einfügen -->
                    <vueper-slides ref="myVueperSlides" fixedHeight=600px :parallax="-1" :parallax-fixed-content="false">
                        <vueper-slide
                            v-for="(slide, i) in slides" :key="i"
                            :image="require(`@/assets/${slide.image}`)" 
                            :style="'min-height: 100%'"/>
                        </vueper-slides>
                    <!-- <div class="withBackgroundImage d-flex justify-content-center"></div> -->
                </div>
            </div>
            <div class="row d-flex justify-content-center description mb-4">
                <div class="col-12 col-md-8">
                    <h3 class="mb-4">Musterwohnung</h3>
                    <p class="text-muted">
                        Nachfolgend finden Sie die Möglichkeit sich eine virtuell erstellte Musterwohnung, hier exemplarisch die Wohnung 29, anzusehen.
                    </p>
                </div>
                <div class="col-12 col-md-8" style="min-height:750px">
                    <iframe style="height:100%; width:100%" class="ku-embed" frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7Prb8?fs=1&vr=1&zoom=1&initload=0&thumbs=1&info=0&logo=-1"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  name: 'Description',
  components: {
    VueperSlides, VueperSlide
  },
    data (){
        return {
            search: true,
            filter: '',
            selectOption: '',
            selectLength: '',
            slides:[
                {
                    image:"JVS90_Perspektiven.jpg",
                    title: 'Slide 1',
                    content: 'Slide 1 content.'
                }
            ]
        }
    },
    methods:{
        
    },
    computed:{
        
    },
    created(){
    }

}
</script>

<style scoped>
@media(max-width:576px){
    .headerPic{
        padding: 0 !important
    }
}
.headerPic{
    padding-left: 15px;
    padding-right: 15px;
}
.description{
    margin-top: 150px;
    font-size: 1.2rem;
}
</style>